import { useMemo } from 'react';
import { isBrowser, isDevEnv, isLocalhost } from './browser';

export interface ImgProxyOptions {
  width?: number;
  quality?: number;
}

export const imageProxyContexts = {
  tiny_square: {
    width: 360,
  },
  small_square: {
    width: 720,
  },
  medium_square: {
    width: 1080,
    quality: 75,
  },
  large_square: {
    width: 1440,
    quality: 75,
  },
  post_header: {
    width: 1440,
    quality: 75,
  },
  image_gallery: {
    width: 800,
    quality: 85,
  },
  // Add additional contexts here as needed...
} as const;

const baseUrl = 'https://dvl-lambda.com';

export const getUrl = (imageUrl: string, { width, quality = 50 }: ImgProxyOptions): string => {
  // if the image is a relative path, prefix with window.location.origin
  if (imageUrl.startsWith('/') && isBrowser()) {
    imageUrl = `${window.location.origin}${imageUrl}`;
  }

  // Construct URL parts based on available options
  const widthPart = width !== undefined ? `width=${width},` : '';
  const qualityPart = `quality=${quality}`;
  const url = `${baseUrl}/cdn-cgi/image/${widthPart}${qualityPart}/${imageUrl}`;

  return url;
};

export type ImageProxyContextsKey = keyof typeof imageProxyContexts;

export interface ImageProxyURLOptions extends ImgProxyOptions {
  context?: ImageProxyContextsKey;
  baseUrl?: string;
}

const shouldIgnoreImageProxy = (imageUrl: string) => {
  if (!imageUrl) return true;

  // Note: SVGs don't need to be proxied since they are already optimized
  if (imageUrl.includes('.svg')) return true;

  // Note: Shopify seemingly blocks the CDN from accessing images
  if (imageUrl.includes('shopify.com')) return true;

  // Note: images on dev environments don't need to be proxied by CDN
  if (isDevEnv()) return true;

  // Note: images on localhost shouldn't be proxied by CDN since they can't be accessed by the CDN
  if (isLocalhost() && imageUrl.startsWith('/')) return true;

  // Note: Chromatic hosts our storybook and is authenticated which can't be accessed by the CDN
  if (isBrowser() && window.location.host.includes('chromatic.com')) return true;

  return false;
};

export const imageProxyURL = (imageUrl: string, options: ImageProxyURLOptions = {}) => {
  if (shouldIgnoreImageProxy(imageUrl)) return imageUrl;

  const { context = 'medium_square', ...restOptions } = options;

  return getUrl(imageUrl, {
    ...(context ? imageProxyContexts[context] : { width: 1080 }),
    ...restOptions,
  });
};

export const getProxySrc = (src?: string, options?: ImageProxyURLOptions) =>
  src?.startsWith('http') || (src && options?.baseUrl && !isLocalhost()) ? imageProxyURL(src, options) : src;

export const useImageProxySrc = (src: string, options?: ImageProxyURLOptions) => {
  return useMemo(() => getProxySrc(src, options), [src, options]);
};
